<template>
  <div class="settings-content-wrapper">
    <router-link :to="{ name: 'settings-home' }" icon='arrowdownlarge' class="is-back"><p><i class="backarrow"></i>BACK</p></router-link>
    <h1>Bank Accounts</h1>
    <p>Set your standard business hours. These times will be used for your appointment calendar.</p>
    <hr>
    <div v-if="bankAccounts">
      <div class="box bunya-box card" v-for="(bankAccount, index) in bankAccounts" v-bind:key="index">
        <router-link :to="{ name: 'settings-bank-accounts-show', params: { id: bankAccount.id }}">
          <div>
            <div class="level is-mobile component-level-margin">
              <div class="level-left">
                <div class="level-item">
                  <h6 class="subtext">{{ bankAccount.accountDetails.nameOnAccount }}</h6>
                </div>
              </div>
              <div class="level-right">
                <div class="level-item">
                  <h6 class="subtext">{{ bankAccount.label }}</h6>
                </div>
              </div>
            </div>
            <div class="level is-mobile">
              <div class="level-left">
                <div>
                  <div class="block level-item">
                    <h4 class="no-line-height has-dashboard-limit">{{ bankAccount.accountDetails.nameOnAccount }}</h4>
                    <h4 class="no-line-height"><span>{{ bankAccount.accountDetails.bsb }} {{ bankAccount.accountDetails.accountNumber }}</span>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </router-link>
      </div>
      <hr>
    </div>
    <div v-else>
      <b-message type="is-danger">
        <strong>No bank accounts have been added.</strong>
      </b-message>
      <hr>
    </div>
    <div class="level">
      <div class="level-left"></div>
      <div class="level-right">
        <div class="level-item">
          <router-link :to="{ name: 'settings-bank-accounts-create' }" class="button is-primary is-fullwidth-mobile">Add bank account</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

  @import '../../scss/_theme-default.scss';

  .card a {
    color: $dark;
  }

  .card a:hover {
    color: lighten($dark, 20);
  }

  .card {
    background-color: #F9F9F9;
  }

  .subtext {
    color: lighten($dark, 40);
  }

</style>

<script>
export default {
  name: 'BankAccounts',
  computed: {
    bankAccounts () {
      return this.$store.getters['settings/bankAccounts']
    }
  },
  mounted () {
    this.$store.dispatch('settings/fetchBankAccounts')
      .then(() => {})
      .catch((err) => {
        this.$buefy.toast.open({
          message: `Error: ${err.message}`,
          type: 'is-danger',
          duration: 4000,
          queue: false
        })
      })
  },
  components: { }
}
</script>
